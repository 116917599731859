import { Alert, Box, useTheme } from "@mui/material";

import { useAuth } from "@/providers";
import {
  Annotations,
  AnnotationViewFilters,
} from "@/shared/components/Annotations";
import { DragAndDropFiles } from "@/shared/components/DragAndDropFiles";
import { FileSystemNode } from "@/shared/domain/files/filesystem";

import { DatasetDisplays } from "./DatasetDisplays";
import { PaginationConfig } from "./state";

interface DragAndDropDisplayProps {
  annotationData: Annotations | undefined;
  annotationViewFilters: AnnotationViewFilters;
  currentPage: FileSystemNode[] | undefined;
  datasetId: string;
  displayMode: string;
  error: Error | null;
  fileInputRef: React.RefObject<HTMLInputElement>;
  filePrefix: string | undefined;
  gridItems: number;
  handleDeleteRow: (row: FileSystemNode) => void;
  handleRowDoubleClick: (row: FileSystemNode) => void;
  handleSelectRows: (rows: FileSystemNode[], isSelected: boolean) => void;
  isLoading: boolean;
  isSearching: boolean;
  nextToken: string | undefined;
  onClearError: () => void;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  onDropComplete: (error?: Error) => Promise<void>;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void;
  onRowsPerPageChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
  ) => void;
  selectedItems: FileSystemNode[];
  tableConfig: PaginationConfig;
  handleActionConfirmation: (
    title: string,
    text: string,
    action: () => Promise<void>,
  ) => void;
}

export const DragAndDropDisplay: React.FC<DragAndDropDisplayProps> = ({
  annotationData,
  annotationViewFilters,
  currentPage,
  datasetId,
  displayMode,
  error,
  fileInputRef,
  filePrefix,
  gridItems,
  nextToken,
  handleDeleteRow,
  handleRowDoubleClick,
  handleSelectRows,
  isLoading,
  isSearching,
  onClearError,
  onChangeInput,
  onDropComplete,
  onPageChange,
  onRowsPerPageChange,
  selectedItems,
  tableConfig,
  handleActionConfirmation,
}) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  let dragAndDropContent;

  if (error) {
    dragAndDropContent = (
      <Box sx={{ mt: theme.spacing(2) }}>
        <Alert onClose={onClearError} severity="error">
          {error.message}
        </Alert>
      </Box>
    );
  } else {
    dragAndDropContent = (
      <DragAndDropFiles
        containerStyle={{
          marginTop: theme.spacing(1),
          backgroundColor: "transparent",
        }}
        displayOverlayOnFileHover={true}
        orgId={currentOrganization?.org_id ?? ""}
        datasetId={datasetId}
        onDropComplete={onDropComplete}
        filePrefix={filePrefix}
      >
        <DatasetDisplays
          annotationData={annotationData}
          annotationViewFilters={annotationViewFilters}
          currentPage={currentPage}
          displayMode={displayMode}
          datasetId={datasetId}
          gridItems={gridItems}
          handleDeleteRow={handleDeleteRow}
          handleRowDoubleClick={handleRowDoubleClick}
          isLoading={isLoading}
          isSearching={isSearching}
          nextToken={nextToken}
          onPageChange={(event, newPage) => {
            onPageChange(event, newPage);
          }}
          onRowsPerPageChange={(event) => {
            onRowsPerPageChange(event);
          }}
          selectedItems={selectedItems}
          handleSelectRows={handleSelectRows}
          tableConfig={tableConfig}
          handleActionConfirmation={handleActionConfirmation}
        />

        <input
          type="file"
          multiple
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => {
            void onChangeInput(e);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }}
        />
      </DragAndDropFiles>
    );
  }

  return dragAndDropContent;
};
