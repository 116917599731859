import { Box, Skeleton, TablePagination, useTheme } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";

import {
  Annotations,
  AnnotationViewFilters,
} from "@/shared/components/Annotations";
import { FileSystemNode } from "@/shared/domain/files/filesystem";

import { DatasetFileGrid } from "./DatasetFileGrid";
import { DatasetFileTable } from "./DatasetFileTable";
import { NoFilesUploadedAlert } from "./NoFilesUploadedAlert";
import { PaginationConfig } from "./state";

interface DatasetDisplaysProps {
  displayMode: string;
  datasetId: string;
  tableConfig: PaginationConfig;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void;
  onRowsPerPageChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
  ) => void;
  currentPage: FileSystemNode[] | undefined;
  isLoading: boolean;
  isSearching: boolean;
  handleDeleteRow: (row: FileSystemNode) => void;
  handleRowDoubleClick: (row: FileSystemNode) => void;
  nextToken: string | undefined;
  gridItems: number;
  annotationData?: Annotations;
  annotationViewFilters: AnnotationViewFilters;
  selectedItems: FileSystemNode[];
  handleActionConfirmation: (
    title: string,
    text: string,
    action: () => Promise<void>,
  ) => void;
  handleSelectRows: (rows: FileSystemNode[], isSelected: boolean) => void;
}

export const DatasetDisplays: React.FC<DatasetDisplaysProps> = ({
  displayMode,
  datasetId,
  tableConfig,
  onPageChange,
  onRowsPerPageChange,
  currentPage,
  isLoading,
  isSearching,
  handleDeleteRow,
  handleRowDoubleClick,
  nextToken,
  gridItems,
  annotationData,
  annotationViewFilters,
  selectedItems,
  handleActionConfirmation,
  handleSelectRows,
}) => {
  const theme = useTheme();

  const totalCount =
    tableConfig.rowsPerPage * tableConfig.page + (currentPage?.length ?? 0);

  const { pathname } = useLocation();
  const cleanPathname = pathname.replace(/^\/+|\/+$/g, "");
  const inFolder = cleanPathname.split("/").length > 2;

  let content;
  if ((currentPage && currentPage.length > 0) || isSearching || inFolder) {
    content = (
      <>
        {displayMode === "table" ? (
          <DatasetFileTable
            currentPage={currentPage ?? []}
            handleDeleteRow={handleDeleteRow}
            handleRowDoubleClick={handleRowDoubleClick}
            handleActionConfirmation={handleActionConfirmation}
            selectedItems={selectedItems}
            handleSelectRows={handleSelectRows}
          />
        ) : (
          <DatasetFileGrid
            datasetId={datasetId}
            currentPage={currentPage ?? []}
            handleRowClick={handleRowDoubleClick}
            gridItems={gridItems}
            annotationViewFilters={annotationViewFilters}
            annotationData={annotationData}
            selectedItems={selectedItems}
            handleSelectRows={handleSelectRows}
          />
        )}
        <TablePagination
          component={Box}
          count={nextToken ? -1 : totalCount}
          page={tableConfig.page}
          rowsPerPage={tableConfig.rowsPerPage}
          labelRowsPerPage={"Items per page:"}
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={(event, newPage) => {
            onPageChange(event, newPage);
          }}
          onRowsPerPageChange={(event) => {
            onRowsPerPageChange(event);
          }}
        />
      </>
    );
  } else {
    content = <NoFilesUploadedAlert datasetId={datasetId} />;
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          marginBottom: theme.spacing(1),
        }}
      >
        <Skeleton animation="wave" variant="rounded" height={150} />
      </Box>
    );
  }

  return content;
};
