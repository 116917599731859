import { Button, useTheme } from "@mui/material";
import * as React from "react";

import { colorForAnnotation } from "@/shared/components/visualization/colorScale";
import { EventRecord } from "@/shared/domain/events";
import {
  useMeasure,
  type Dimensions,
  type OnDimensionsChange,
} from "@/shared/hooks";
import { useDebouncedCallback } from "@/shared/hooks/useDebouncedCallback";
import { ErrorMonitoringService } from "@/shared/services";
import {
  EventConfig,
  LayoutItem,
  PlotPanelState,
} from "@/shared/state/visualization";
import { useEventConfigs } from "@/shared/state/visualization/hooks";

import {
  useEphemeralWorkspaceStateLoading,
  useWorkspaceEventsForTopicsAndMessagePaths,
  useWorkspaceTimeBounds,
  useWorkspaceTimer,
} from "../../WorkspaceCtx";
import { NoDataMessage } from "../NoDataMessage";
import { PanelHeader } from "../PanelHeader";
import { PanelLayout } from "../PanelLayout";
import { RenderingError } from "../RenderingError";

import { Annotations } from "./Annotations";
import { Legend } from "./Legend";
import { PlotEvent, RenderedAnnotation } from "./messaging";
import { TimeSpanAnnotation } from "./messaging/commands";
import { usePlotPanelContext } from "./panelContext";
import { PlotManager } from "./PlotManager";
import styles from "./PlotPanel.module.css";
import type { Extents } from "./PlotRenderer";
import { PlotTool, Toolbar } from "./plotTools";
import { SelectRegion } from "./SelectRegion";
import { TimelineIndicator } from "./TimelineIndicator";
import { Tooltip } from "./Tooltip";
import { useViewport, Viewport } from "./Viewport";

interface PlotPanelProps {
  layout: LayoutItem;
  state: PlotPanelState;
}

const zoomInteractionConfig = {
  speed: 0.1, // 10% per zoom
};

const viewportHooks = {
  useBroadcastLocalViewport: (localViewport: Viewport) => {
    // Track the previous viewport to identify when a change has occurred.
    const prevLocalViewport = React.useRef(localViewport);
    const { isViewportSynced } = usePlotPanelContext();
    const [, setSharedViewport] = useViewport();

    React.useEffect(
      function onLocalViewportChanged() {
        if (prevLocalViewport.current.isOlderThan(localViewport)) {
          if (isViewportSynced) {
            setSharedViewport(localViewport);
          }
        }
        prevLocalViewport.current = localViewport;
      },
      [localViewport, isViewportSynced, setSharedViewport],
    );
  },

  useOnSyncEnabled: (localViewport: Viewport) => {
    const { isViewportSynced } = usePlotPanelContext();
    // Track the previous viewport to identify when a change has occurred.
    const prevIsViewportSynced = React.useRef(isViewportSynced);
    const [, setSharedViewport] = useViewport();

    // When this chart starts synchronizing its viewport, sync all other charts' viewports to this one.
    React.useEffect(
      function onViewportSyncEnabled() {
        if (isViewportSynced && !prevIsViewportSynced.current) {
          setSharedViewport(
            new Viewport({
              ...localViewport.getParams(),
              // Because the viewport was just set to synchronize, broadcast
              // this panel's current viewport as the latest viewport.
              debounceId: Viewport.latestDebounceId(),
            }),
          );
        }
        prevIsViewportSynced.current = isViewportSynced;
      },
      [localViewport, isViewportSynced, setSharedViewport],
    );
  },

  useApplySharedViewport: (
    plotManager: PlotManager | null,
    isDragging: React.RefObject<boolean>,
  ) => {
    const { isViewportSynced } = usePlotPanelContext();
    const [sharedViewport] = useViewport();
    React.useEffect(
      function applySharedViewport() {
        if (!isViewportSynced || isDragging.current) {
          return;
        }
        plotManager?.applyViewport(sharedViewport);
      },
      [sharedViewport, isViewportSynced, plotManager, isDragging],
    );
  },
};

const plotHooks = {
  useZoom: (
    plotManager: PlotManager | null,
    canvasRef: React.RefObject<HTMLCanvasElement | null>,
  ) => {
    const { activeTool } = usePlotPanelContext();

    React.useEffect(
      function addPlotZoomListener() {
        const canvas = canvasRef.current;
        if (canvas === null || plotManager === null) {
          return;
        }

        if (activeTool !== PlotTool.Move) {
          return;
        }

        const abortController = new AbortController();

        canvas.addEventListener(
          "wheel",
          function zoom(event: WheelEvent) {
            // Prevent the event from triggering the default scroll behavior
            if (event.cancelable) {
              event.preventDefault();
            }

            const { target } = event;

            if (target instanceof Element) {
              const rect = target.getBoundingClientRect();
              const offsetX = event.clientX - rect.left;
              const offsetY = event.clientY - rect.top;

              const center = {
                x: offsetX,
                y: offsetY,
              };

              let zoomSpeedRatio = zoomInteractionConfig.speed;

              // Swap the sign of the zoom speed if the user is zooming out
              if (event.deltaY >= 0) {
                zoomSpeedRatio = -zoomSpeedRatio;
              }

              plotManager.zoom({
                zoomRatioX: zoomSpeedRatio,
                zoomRatioY: zoomSpeedRatio,
                center,
                axis: "x",
              });
            }
          },
          { signal: abortController.signal, passive: false },
        );

        return function dispose() {
          abortController.abort();
        };
      },
      [plotManager, canvasRef, activeTool],
    );
  },

  usePan: (
    plotManager: PlotManager | null,
    canvasRef: React.RefObject<HTMLCanvasElement | null>,
    isDragging: React.MutableRefObject<boolean>,
  ) => {
    const { activeTool } = usePlotPanelContext();

    React.useEffect(
      function addPlotPanListeners() {
        const canvas = canvasRef.current;
        if (canvas === null || plotManager === null) {
          return;
        }

        if (activeTool !== PlotTool.Move) {
          return;
        }

        const abortController = new AbortController();

        canvas.addEventListener(
          "pointerdown",
          function panStart(event: PointerEvent) {
            const eventAbortController = new AbortController();

            let x: number | null = event.clientX;
            let y: number | null = event.clientY;

            canvas.addEventListener(
              "pointermove",
              function pan(event: PointerEvent) {
                if (x === null || y === null) {
                  // Set x and y then return for next time
                  x = event.clientX;
                  y = event.clientY;
                  return;
                }

                isDragging.current = true;
                canvas.style.cursor = "grabbing";

                const deltaX = event.clientX - x;
                const deltaY = event.clientY - y;

                plotManager.pan({ deltaX, deltaY });

                // Save the current pointer position for the next difference
                x = event.clientX;
                y = event.clientY;
              },
              {
                signal: eventAbortController.signal,
              },
            );

            canvas.addEventListener(
              "pointerup",
              function panEnd() {
                // Clean up event listeners using abort controller
                eventAbortController.abort();

                canvas.style.cursor = "crosshair";

                // Reset closure variables for next drag event
                x = null;
                y = null;

                /* 
              Schedules setting isDragging to false on the next iteration of the event loop
              after all the immediate listeners have been called. This is to prevent
              subsequent click events from occurring at the same time as when the drag completes.
            */
                setTimeout(function resetDragging() {
                  isDragging.current = false;
                }, 0);
              },
              { once: true },
            );

            const panCancel = () => {
              // Clean up event listeners using abort controller
              eventAbortController.abort();

              // Reset closure variables for next drag event
              x = null;
              y = null;

              isDragging.current = false;
              canvas.style.cursor = "crosshair";
            };

            canvas.addEventListener("pointercancel", panCancel, { once: true });
            canvas.addEventListener("pointerleave", panCancel, { once: true });
          },
          { signal: abortController.signal },
        );

        return function dispose() {
          abortController.abort();
        };
      },
      [plotManager, canvasRef, isDragging, activeTool],
    );
  },

  /**
   * Resize the plot when the canvas is resized
   */
  useMeasureCanvas: (plotManager: PlotManager | null) => {
    const onRenderingSurfaceResize = React.useCallback(
      ({ width, height }: Dimensions) => {
        if (plotManager === null) {
          return;
        }

        plotManager.resize({ width, height });
      },
      [plotManager],
    );

    const onRenderingSurfaceResizeDebounced =
      useDebouncedCallback<OnDimensionsChange>(onRenderingSurfaceResize, 150);

    const [measured] = useMeasure<HTMLDivElement>({
      onDimensionsChange: onRenderingSurfaceResizeDebounced,
    });

    return measured;
  },

  /**
   * Add/remove series as appropriate when plot state changes
   */
  useOnStateUpdated: (
    plotManager: PlotManager | null,
    state: PlotPanelState,
  ) => {
    const [fileIds, topicIds, messagePathIds] = React.useMemo(() => {
      const fileIds: string[] = [];
      const topicIds: string[] = [];
      const messagePathIds: string[] = [];

      state.data.forEach((series) => {
        fileIds.push(series.data.topic.association.association_id);
        topicIds.push(series.data.topic.id);
        messagePathIds.push(series.data.messagePath.id);
      });

      return [fileIds, topicIds, messagePathIds];
    }, [state.data]);

    const timeBounds = useWorkspaceTimeBounds(fileIds);
    const events = useWorkspaceEventsForTopicsAndMessagePaths(
      topicIds,
      messagePathIds,
    );
    const eventConfigs = useEventConfigs();
    React.useEffect(
      function updatePlotState() {
        if (
          plotManager === null ||
          timeBounds.earliest === undefined ||
          timeBounds.latest === undefined
        ) {
          return;
        }

        const abortController = new AbortController();
        const annotations = eventsToPlotAnnotations(events, eventConfigs);

        plotManager.setState(
          state.data,
          annotations,
          timeBounds,
          abortController.signal,
        );

        return () => {
          abortController.abort();
        };
      },
      [plotManager, state.data, timeBounds, events, eventConfigs],
    );
  },

  /**
   * Update the plot style when the theme (light/dark mode) changes
   */
  useOnThemeChanged: (plotManager: PlotManager | null) => {
    const theme = useTheme();
    React.useEffect(
      function updatePlotStyle() {
        if (plotManager === null) {
          return;
        }

        plotManager.setStyle({ mode: theme.palette.mode });
      },
      [plotManager, theme.palette.mode],
    );
  },
};

const timerHooks = {
  useHoldWhileLoading: (
    plotManager: PlotManager | null,
    setIsPlotLoading: (arg0: boolean) => void,
  ) => {
    const timer = useWorkspaceTimer();
    /**
     * Hold the timer while the plot is loading
     */
    const timerHoldRef = React.useRef<symbol | null>(null);
    React.useEffect(
      function holdTimerWhenLoading() {
        if (plotManager === null) {
          return;
        }

        const abortController = new AbortController();

        plotManager.setEventListener(
          PlotEvent.LoadingStateChange,
          ({ isLoading }) => {
            if (abortController.signal.aborted) {
              return;
            }
            if (isLoading && timerHoldRef.current === null) {
              timerHoldRef.current = timer.hold();
            }
            if (!isLoading && timerHoldRef.current !== null) {
              timer.holdRelease(timerHoldRef.current);
              timerHoldRef.current = null;
            }

            setIsPlotLoading(isLoading);
          },
        );

        return function cleanUp() {
          abortController.abort();
          if (timerHoldRef.current !== null) {
            timer.holdRelease(timerHoldRef.current);
            timerHoldRef.current = null;
          }
        };
      },
      [plotManager, setIsPlotLoading, timer],
    );
  },

  /**
   * Seek Timer to a specific time when the user clicks on the plot.
   */
  useSeekToClick: (
    chartExtents: Extents | null,
    canvasRef: React.RefObject<HTMLCanvasElement | null>,
    isDragging: React.RefObject<boolean>,
  ) => {
    const timer = useWorkspaceTimer();
    React.useEffect(
      function attachSeekToListener() {
        if (chartExtents === null || canvasRef.current === null) {
          return;
        }
        const canvas = canvasRef.current;
        if (canvas === null) {
          return;
        }

        const abortController = new AbortController();
        function seekToTime(event: MouseEvent) {
          if (chartExtents === null || canvas === null) {
            return;
          }

          // Don't seek while dragging
          if (isDragging.current) {
            return;
          }

          const {
            pixel: { left: canvasScaleLeftOffset, width },
            data: {
              logTime: { min, max },
            },
          } = chartExtents;

          const canvasBox = canvas.getBoundingClientRect();

          const x = Math.max(
            Math.min(
              event.clientX - canvasScaleLeftOffset - canvasBox.left,
              width,
            ),
            0,
          );

          const pct = x / width;
          const nextTime = min + BigInt(Math.round(Number(max - min) * pct));

          timer.seekTo(nextTime);
        }
        canvas.addEventListener("click", seekToTime, {
          signal: abortController.signal,
        });

        return function detachSeekToListener() {
          abortController.abort();
        };
      },
      [chartExtents, canvasRef, isDragging, timer],
    );
  },
};

function eventsToPlotAnnotations(
  events: EventRecord[],
  eventsConfig: Record<string, EventConfig> | undefined,
): TimeSpanAnnotation[] {
  return events
    .filter((event) => {
      const eventConfig = eventsConfig?.[event.event_id] ?? { isVisible: true };
      return eventConfig.isVisible;
    })
    .map((event) => ({
      annotationId: event.event_id,
      startTime: event.start_time,
      endTime: event.end_time,
      style: {
        labelText: event.name,
        labelColor: "black",
        backgroundColor: colorForAnnotation(
          event.name,
          0.2,
          event.display_options?.color,
        ),
      },
    }));
}

export function PlotPanel({ layout, state }: PlotPanelProps) {
  const theme = useTheme();

  const timer = useWorkspaceTimer();

  const isWorkspaceLoading = useEphemeralWorkspaceStateLoading();

  const canvasParentRef = React.useRef<HTMLDivElement | null>(null);
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const [chartExtents, setChartExtents] = React.useState<Extents | null>(null);
  const [isPlotLoading, setIsPlotLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [plotManager, setPlotManager] = React.useState<PlotManager | null>(
    null,
  );
  const [annotations, setAnnotations] = React.useState<RenderedAnnotation[]>(
    [],
  );

  const localViewport = React.useMemo(
    () =>
      chartExtents
        ? new Viewport(chartExtents.data.normalizedViewport)
        : Viewport.default(0),
    [chartExtents],
  );
  const hasViewChanged = React.useMemo(
    () => state.data.length > 0 && !localViewport.matchesDefaultViewport(),
    [state.data, localViewport],
  );
  const isDragging = React.useRef(false);

  /**
   * Initialize the plot manager and clean up when the component is unmounted
   */
  React.useEffect(
    function init() {
      const div = canvasParentRef.current;
      if (div === null || error !== null) {
        return;
      }

      const abortController = new AbortController();

      const bounds = div.getBoundingClientRect();
      const canvas = document.createElement("canvas");
      canvasRef.current = canvas;
      canvas.width = bounds.width;
      canvas.height = bounds.height;
      canvas.classList.add(styles.canvas);
      div.insertBefore(canvas, div.firstChild);

      const manager = new PlotManager({
        canvas: canvas.transferControlToOffscreen(),
        devicePixelRatio: window.devicePixelRatio,
      });

      manager.setEventListener(PlotEvent.Error, (err) => {
        if (abortController.signal.aborted || err.name === "AbortError") {
          return;
        }
        const error = new Error(err.message, { cause: err });
        ErrorMonitoringService.captureError(error);
        setError(error);
        setIsPlotLoading(false);
        setPlotManager(null);
      });

      manager.setEventListener(
        PlotEvent.Rendered,
        ({ extents, annotations }) => {
          if (abortController.signal.aborted) {
            return;
          }
          setAnnotations(annotations);
          setChartExtents(extents);
        },
      );

      manager.setEventListener(PlotEvent.Initialized, () => {
        if (abortController.signal.aborted) {
          return;
        }

        setPlotManager(manager);
      });

      return function dispose() {
        abortController.abort();
        manager.dispose();
        canvasRef.current = null;
        div.removeChild(canvas);
      };
    },
    [error],
  );

  viewportHooks.useBroadcastLocalViewport(localViewport);
  viewportHooks.useOnSyncEnabled(localViewport);
  viewportHooks.useApplySharedViewport(plotManager, isDragging);

  plotHooks.useZoom(plotManager, canvasRef);
  plotHooks.usePan(plotManager, canvasRef, isDragging);
  plotHooks.useOnStateUpdated(plotManager, state);
  plotHooks.useOnThemeChanged(plotManager);
  const measured = plotHooks.useMeasureCanvas(plotManager);

  timerHooks.useHoldWhileLoading(plotManager, setIsPlotLoading);
  timerHooks.useSeekToClick(chartExtents, canvasRef, isDragging);

  const onDragChanged = React.useCallback((_isDragging: boolean) => {
    isDragging.current = _isDragging;
  }, []);

  return (
    <PanelLayout
      header={
        <PanelHeader
          additionalTools={<Toolbar />}
          className={styles.panelHeader}
          state={state}
        />
      }
      isLoading={isWorkspaceLoading || isPlotLoading}
      layout={layout}
      state={state}
    >
      <div
        className={styles.renderingSurface}
        ref={(node) => {
          if (node !== null) {
            measured(node);
          }
          canvasParentRef.current = node;
        }}
      >
        {error === null && (
          <TimelineIndicator chartExtents={chartExtents} timer={timer} />
        )}
        <Annotations chartExtents={chartExtents} annotations={annotations} />
        {error === null && <Legend state={state} />}
        {!layout.isResizing && (
          <Tooltip
            chartExtents={chartExtents}
            canvas={canvasRef.current}
            plotManager={plotManager}
            state={state}
          />
        )}
        <SelectRegion
          chartExtents={chartExtents}
          onDragChanged={onDragChanged}
          renderingSurface={canvasParentRef.current}
          state={state}
        />
        {hasViewChanged && (
          <Button
            className={styles.resetViewBtn}
            variant="outlined"
            size="small"
            onClick={function resetView(event) {
              // Prevent triggering the other chart onclick handlers
              event.stopPropagation();
              plotManager?.resetView();
            }}
            style={{
              backgroundColor: theme.palette.panel.main,
            }}
          >
            Reset View
          </Button>
        )}
        <NoDataMessage panelData={state.data} />
        <RenderingError error={error} onClearError={() => setError(null)} />
      </div>
    </PanelLayout>
  );
}
