import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { constructTree, findMessagePathNode } from "@/shared/domain/topics";
import { useMessagePathById } from "@/shared/domain/topics/hooks/useMessagePath";
import { useTopicById } from "@/shared/domain/topics/hooks/useTopic";
import { LoggerService } from "@/shared/services";

import { MessagePathLink } from "./MessagePathLink";

const linkErrorComponent = (
  <Typography variant="body2" color="error">
    Failed to load link
  </Typography>
);

interface MessagePathLinkFromIdProps {
  messagePathId: string;
}

export const MessagePathLinkFromId: React.FC<MessagePathLinkFromIdProps> = ({
  messagePathId,
}) => {
  const mpQuery = useMessagePathById(messagePathId);
  const topicQuery = useTopicById(mpQuery.data?.topic_id);

  const messagePathNode = React.useMemo(() => {
    if (topicQuery.data && mpQuery.data) {
      const topicTree = constructTree([topicQuery.data])[0];
      return findMessagePathNode(topicTree, mpQuery.data.message_path);
    }
    return null;
  }, [topicQuery.data, mpQuery.data]);

  if (mpQuery.isPending || topicQuery.isPending) {
    return (
      <Typography variant="body2">
        <Skeleton variant="text" width="200px" />
      </Typography>
    );
  }

  if (mpQuery.isError || topicQuery.isError) {
    if (mpQuery.isError) {
      LoggerService.error(mpQuery.error?.message, mpQuery.error);
    }

    if (topicQuery.isError) {
      LoggerService.error(topicQuery.error?.message, topicQuery.error);
    }
    return linkErrorComponent;
  }

  return (
    <>
      {messagePathNode && topicQuery.data && (
        <MessagePathLink
          messagePathNode={messagePathNode}
          topicAssociation={topicQuery.data.association}
          linkPrefix={`${topicQuery.data.topic_name}.`}
        />
      )}
    </>
  );
};
