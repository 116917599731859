import * as Sentry from "@sentry/react";

import { OrgRecord } from "@/shared/domain/orgs";
import { UserRecord } from "@/shared/domain/users";
import { Env, isProductionLikeStage } from "@/shared/environment";

import { LoggerService } from "./LoggerService";

class ErrorMonitoringService {
  constructor() {
    if (isProductionLikeStage()) {
      Sentry.init({
        dsn: Env.sentryDsn,
        environment: Env.appEnv,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],

        /*************
         *  Tracing  *
         *************/
        // Capture only 10% of the transactions. Sentry's recommendation is to start small
        // then ramp up once we have a stable baseline.
        tracesSampleRate: 0.1,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", Env.robotoServiceBaseUrl],

        /********************
         *  Session Replay  *
         ********************/
        // We will disable general session replays and only record sessions when errors occur.
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }

  public captureError(error: unknown, extraData?: Record<string, unknown>) {
    LoggerService.error(error);
    Sentry.captureException(error, extraData);
  }

  public setUser(user: UserRecord) {
    Sentry.setUser({
      id: user.user_id,
      email: user.user_id,
      name: user.name,
    });
  }

  public setOrg(org: OrgRecord) {
    Sentry.setContext("Organization", org);
  }
}

const ErrorMonitoringServiceInstance = new ErrorMonitoringService();
export { ErrorMonitoringServiceInstance as ErrorMonitoringService };
