import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Box, Chip, IconButton, LinearProgress, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoTooltip } from "@/shared/components";
import {
  useAllWorkspaceEvents,
  useWorkspaceTimeBounds,
  useWorkspaceTimer,
} from "@/shared/components/visualization/WorkspaceCtx";
import { EventRecord } from "@/shared/domain/events";
import { clamp } from "@/shared/math";
import { useEventConfigs } from "@/shared/state/visualization/hooks";
import { nanoSecToLocalTimestamp, nanoSecToRosTimeStamp } from "@/shared/time";

import { useTimerState } from "../timer";

import { EventTimeline } from "./EventTimeline";
import { PlaybackSpeedButton } from "./PlaybackSpeedButton";
import styles from "./Timeline.module.css";

enum IndicatorMode {
  Elapsed,
  Log,
  Local,
}

const INDICATOR_MODES = [
  IndicatorMode.Elapsed,
  IndicatorMode.Log,
  IndicatorMode.Local,
];

const SEC_IN_MS = 1000;

/**
 * This is a container around an EventRecord which also holds the start and end times of the event in workspace timer
 * domain relative duration milliseconds.
 */
interface RenderableEvent {
  startMs: number;
  endMs: number;
  eventRecord: EventRecord;
}

/**
 * A visualization of the time spans of the topics represented in the visualization workspace
 * and the current state of the timer that acts as a playhead.
 * Exposes controls to play/pause the timer and seek to a specific time.
 */
export function Timeline() {
  const theme = useTheme();
  const events = useAllWorkspaceEvents();
  const timeBounds = useWorkspaceTimeBounds();
  const timer = useWorkspaceTimer();
  const eventConfigs = useEventConfigs();

  const { duration, elapsedMs, isPlaying, isHeld, timeNs } =
    useTimerState(timer);

  const progressRef = React.useRef<HTMLSpanElement | null>(null);
  const holdRef = React.useRef<symbol | null>(null);
  const playPauseRef = React.useRef<HTMLButtonElement>(null);
  const skipForwardRef = React.useRef<HTMLButtonElement>(null);
  const skipBackwardRef = React.useRef<HTMLButtonElement>(null);

  const [renderableEvents, setRenderableEvents] = React.useState<
    RenderableEvent[]
  >([]);

  const [indicatorIndex, setIndicatorIndex] = React.useState<number>(2);

  const updateRenderableEvents = React.useCallback(() => {
    setRenderableEvents(
      events
        .filter((event) => {
          // Default to visible if config is undefined
          const config = eventConfigs?.[event.event_id] ?? { isVisible: true };
          return config.isVisible;
        })
        .map((event) => {
          const eventStartMs = timer.durationFromAbsolute(event.start_time);
          const eventEndMs = timer.durationFromAbsolute(event.end_time);
          return {
            startMs: eventStartMs,
            endMs: eventEndMs,
            eventRecord: event,
          };
        }),
    );
  }, [events, eventConfigs, timer]);

  // If the timespans change, we need to update renderable events by getting new duration projected timestamps
  // for each event
  React.useEffect(
    function setTimerCallbacks() {
      const abortController = new AbortController();
      timer.addListener("timeSpanUpdate", updateRenderableEvents, {
        signal: abortController.signal,
      });
      return function removeListener() {
        abortController.abort();
      };
    },
    [timer, updateRenderableEvents],
  );

  // Update renderable events when the events change as well
  React.useEffect(() => {
    updateRenderableEvents();
  }, [events, updateRenderableEvents]);

  const onPlayPause = () => {
    if (isPlaying) {
      timer.stop();
    } else {
      if (timer.finished) {
        timer.reset();
      }
      timer.start();
    }
  };

  const seekToCursor = React.useCallback(
    (event: MouseEvent) => {
      const progressEl = progressRef.current;
      if (!progressEl) {
        return;
      }

      // Prevent text selection while dragging
      event.preventDefault();

      const rect = progressEl.getBoundingClientRect();
      const x = clamp(event.clientX, rect.left, rect.right) - rect.left;
      const percent = x / rect.width;
      const elapsed = Math.round(timer.duration * percent);
      timer.seek(elapsed);
    },
    [timer],
  );

  const onSkipForward = () => {
    const newElapsedMs = Math.min(elapsedMs + SEC_IN_MS, duration);
    timer.seek(newElapsedMs);
  };

  const onSkipBackward = () => {
    const newElapsedMs = Math.max(elapsedMs - SEC_IN_MS, 0);
    timer.seek(newElapsedMs);
  };

  const onMouseUp = React.useCallback(() => {
    window.removeEventListener("mousemove", seekToCursor);
    if (holdRef.current) {
      timer.holdRelease(holdRef.current);
      holdRef.current = null;
    }
  }, [seekToCursor, timer]);

  const onMouseDown = (event: React.MouseEvent) => {
    const progressEl = progressRef.current;
    if (!progressEl) {
      return;
    }

    if (timer.active) {
      holdRef.current = timer.hold();
    }

    seekToCursor(event.nativeEvent);
    window.addEventListener("mouseup", onMouseUp, { once: true });
    window.addEventListener("mousemove", seekToCursor);
  };

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the focused element is an input, textarea, or select
      // We don't want to conflict with keyboard input on these fields
      const activeElement = document.activeElement;
      if (
        activeElement &&
        (activeElement.tagName === "INPUT" ||
          activeElement.tagName === "TEXTAREA" ||
          activeElement.tagName === "SELECT")
      ) {
        return;
      }

      if (event.key === "ArrowRight" && skipForwardRef.current && !isPlaying) {
        skipForwardRef.current.click();
      } else if (
        event.key === "ArrowLeft" &&
        skipBackwardRef.current &&
        !isPlaying
      ) {
        skipBackwardRef.current.click();
      } else if (event.key === " " && playPauseRef.current) {
        event.preventDefault(); // Prevent default spacebar action (scrolling)
        playPauseRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPlaying]);

  const buttonIcon = isPlaying ? <PauseIcon /> : <PlayArrowIcon />;

  const workspaceRelativeTime = timeNs - (timeBounds.earliest ?? 0n);
  const progress = (elapsedMs / duration) * 100;

  let indicator: string | number = nanoSecToRosTimeStamp(workspaceRelativeTime);

  if (INDICATOR_MODES[indicatorIndex] === IndicatorMode.Log) {
    indicator = nanoSecToRosTimeStamp(timeNs);
  } else if (INDICATOR_MODES[indicatorIndex] === IndicatorMode.Local) {
    indicator = nanoSecToLocalTimestamp(timeNs);
  }

  // Absolute positioning
  // Linear progress bar + events bar on same top left position relative to parent
  // Define a parent w/ relative position
  // Events bar first, absolute position 0,0, height same as linear progress bar, width same as parent container
  // Linear progress bar same dimensions

  const timeDisplay = duration > 0 && (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
      }}
    >
      <RobotoTooltip title={"Time Format"}>
        <Chip
          icon={
            <TimerOutlinedIcon
              fontSize="small"
              style={{ padding: theme.spacing(0.25) }}
            />
          }
          label={IndicatorMode[indicatorIndex]}
          variant="outlined"
          onClick={(e) => {
            setIndicatorIndex((prev) => (prev + 1) % INDICATOR_MODES.length);
            e.currentTarget.blur();
          }}
          style={{
            padding: theme.spacing(0.5),
          }}
        />
      </RobotoTooltip>
      {indicator}
    </Box>
  );

  return (
    <div className={styles.container}>
      <div className={styles.controller}>
        <div className={styles.current}>{timeDisplay}</div>
        <div className={styles.controls}>
          <IconButton
            onClick={onSkipBackward}
            disabled={isPlaying || isHeld}
            ref={skipBackwardRef}
          >
            <SkipPreviousIcon />
          </IconButton>
          <IconButton
            disabled={isHeld}
            onClick={onPlayPause}
            ref={playPauseRef}
          >
            {buttonIcon}
          </IconButton>
          <IconButton
            onClick={onSkipForward}
            disabled={isPlaying || isHeld}
            ref={skipForwardRef}
          >
            <SkipNextIcon />
          </IconButton>
        </div>
        <div className={styles.playbackSpeedControls}>
          <PlaybackSpeedButton />
        </div>
      </div>
      <Box
        sx={{
          position: "relative",
          height: "30px",
          marginTop: "8px",
        }}
      >
        <EventTimeline events={renderableEvents} startMs={0} endMs={duration} />

        <LinearProgress
          classes={{
            root: styles.track,
            bar: styles.progress,
          }}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
          }}
          onMouseDown={onMouseDown}
          ref={progressRef}
          variant="determinate"
          value={Number.isNaN(progress) ? 0 : progress}
        />
      </Box>
    </div>
  );
}
