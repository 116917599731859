import LayersClearIcon from "@mui/icons-material/LayersClear";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ShareIcon from "@mui/icons-material/Share";
import { Box, Breadcrumbs, Button, Link, useTheme } from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";
import { SharedWorkspaceLinkModal } from "@/shared/components";
import { basename } from "@/shared/domain/files/filesystem";
import { WorkspaceRecord } from "@/shared/domain/workspaces/WorkspaceRecord";
import { usePopupState } from "@/shared/hooks";
import { ErrorMonitoringService } from "@/shared/services";
import {
  actions,
  useFiles,
  useVizState,
  VizStateDispatchContext,
} from "@/shared/state/visualization";
import { pluralize, truncate } from "@/shared/text";

import { AddPanel } from "../controls/AddPanel";
import { ToggleSidebar } from "../controls/ToggleSidebar";

import styles from "./Header.module.css";

const MAX_FILENAME_CHARACTERS = 65;

interface HeaderProps {
  showHeader?: boolean;
}

export function Header({ showHeader = true }: HeaderProps) {
  const dispatch = React.useContext(VizStateDispatchContext);
  const theme = useTheme();
  const state = useVizState();
  const files = useFiles();

  const { workspaceService } = React.useContext(DomainServicesContext);

  const { currentOrganization } = useAuth();

  const workspacePopup = usePopupState();
  const [record, setRecord] = React.useState<WorkspaceRecord | null>(null);
  const [createWorkspaceError, setCreateWorkspaceError] =
    React.useState<Error | null>(null);

  const fileNames = files
    .map((file) => basename(file?.relativePath ?? ""))
    .join(", ");

  if (!showHeader) {
    return null;
  }

  return (
    <Box className={styles.container} bgcolor={theme.palette.code.main}>
      <div className={styles.navigation}>
        <ToggleSidebar />
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="hover"
            color="inherit"
            className={styles.breadcrumbItem}
            href="#"
          >
            <QueryStatsIcon sx={{ mr: 1 }} fontSize="inherit" />
            Visualize
          </Link>
          <Link
            underline="hover"
            color="text.primary"
            className={styles.breadcrumbItem}
            href="#"
          >
            {files.length === 1 ? (
              <span title={fileNames}>
                {truncate(basename(files[0]?.relativePath ?? ""), {
                  characterLimit: MAX_FILENAME_CHARACTERS,
                })}
              </span>
            ) : (
              <span title={fileNames}>
                {files.length} {pluralize("file", files.length)}
              </span>
            )}
          </Link>
        </Breadcrumbs>
      </div>
      <Box
        className={styles.actions}
        sx={{
          display: {
            xs: "none",
            sm: "flex",
          },
          minHeight: "2.3rem",
        }}
      >
        <AddPanel />
        <Button
          color="error"
          className={styles.actionButton}
          disableElevation
          onClick={() => dispatch(actions.removeAllPanels())}
          size="small"
          startIcon={<LayersClearIcon />}
          variant="outlined"
        >
          Clear
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disableElevation
          startIcon={<ShareIcon />}
          className={styles.actionButton}
          size="small"
          onClick={() => {
            const orgId = currentOrganization?.org_id || "";

            workspaceService
              .createWorkspace(state, orgId)
              .then((record) => {
                setRecord(record);
                setCreateWorkspaceError(null);
              })
              .catch((e: Error) => {
                ErrorMonitoringService.captureError(e);
                setCreateWorkspaceError(e);
                setRecord(null);
              })
              .finally(workspacePopup.open);
          }}
        >
          Share
        </Button>
        <SharedWorkspaceLinkModal
          open={workspacePopup.isOpen}
          record={record}
          handleClose={() => {
            setRecord(null);
            workspacePopup.close();
          }}
          error={createWorkspaceError}
        />
      </Box>
    </Box>
  );
}
