import { Alert, Avatar, Box, CircularProgress, useTheme } from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import {
  CommentRecord,
  CreateCommentRequest,
  CreateCommentResponse,
  CommentEntityType,
  UpdateCommentResponse,
  UserSuggestion,
} from "@/shared/domain/comments";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import {
  APIServiceError,
  RobotoAPICall,
  commentsEndpoint,
  updateCommentEndpoint,
} from "@/types";

import { CommentInput } from "./CommentInput";

interface AddEditCommentProps {
  entityId: string;
  entityType: CommentEntityType;
  onCommentSaved: (comment: CommentRecord) => void;
  onCancelClicked?: () => void;
  commentToEdit?: CommentRecord;
}

export const AddEditComment: React.FC<AddEditCommentProps> = ({
  entityId,
  entityType,
  onCommentSaved,
  onCancelClicked,
  commentToEdit,
}) => {
  const theme = useTheme();

  const { currentUser, currentOrganization, orgRoles } = useAuth();

  const { loading, initiateRequest } = useLazyAPICall<CreateCommentResponse>();

  const { loading: loadingUpdate, initiateRequest: initiateUpdateRequest } =
    useLazyAPICall<UpdateCommentResponse>();
  const [error, setError] = React.useState<APIServiceError | null>(null);

  const [userSuggestionList, setUserSuggestionList] = React.useState<
    UserSuggestion[]
  >([]);

  React.useEffect(() => {
    if (!orgRoles) {
      return;
    }

    const validRoles = orgRoles.filter((role) => {
      return !role.user.is_service_user;
    });

    setUserSuggestionList(
      validRoles.map((role) => {
        const suggestion = {
          id: role.user.user_id,
          display: role.user?.name ?? role.user.user_id,
        } as UserSuggestion;

        return suggestion;
      }),
    );
  }, [orgRoles, currentOrganization]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
      }}
    >
      {loading || loadingUpdate ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress size="1.5rem" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Avatar
              src={currentUser?.picture_url}
              imgProps={{ referrerPolicy: "no-referrer" }}
              sx={{ width: 38, height: 38 }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: theme.spacing(2),
              width: "100%",
              justifyContent: "center",
              cursor: "text",
            }}
          >
            {error && (
              <Alert
                onClose={() => setError(null)}
                severity="error"
                sx={{ mb: theme.spacing(1) }}
              >
                {error.message}
              </Alert>
            )}
            <CommentInput
              userSuggestionList={userSuggestionList}
              handleSaveComment={(commentText) => {
                void handleSaveComment(
                  commentText,
                  entityType,
                  entityId,
                  commentToEdit,
                  currentOrganization?.org_id,
                  initiateRequest,
                  initiateUpdateRequest,
                  onCommentSaved,
                  setError,
                );
              }}
              onCancelClicked={onCancelClicked}
              commentToEdit={commentToEdit}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

const handleSaveComment = async (
  commentText: string,
  entityType: CommentEntityType,
  entityId: string,
  commentToEdit: CommentRecord | undefined,
  orgId: string | undefined,
  initiateRequest: (apiCall: RobotoAPICall) => Promise<{
    error: APIServiceError | null;
    data: CreateCommentResponse | null;
  }>,
  initiateUpdateRequest: (apiCall: RobotoAPICall) => Promise<{
    error: APIServiceError | null;
    data: UpdateCommentResponse | null;
  }>,
  onCommentSaved: (comment: CommentRecord) => void,
  onError: (error: APIServiceError) => void,
) => {
  if (commentToEdit) {
    const apiCall: RobotoAPICall = {
      endpoint: updateCommentEndpoint,
      method: "PUT",
      pathParams: {
        commentId: commentToEdit.comment_id,
      },
      requestBody: JSON.stringify({
        comment_text: commentText,
      }),
      orgId: orgId,
    };

    const { data, error } = await initiateUpdateRequest(apiCall);
    if (error) {
      onError(error);
      return;
    }

    if (data?.data) {
      onCommentSaved(data.data);
    }
  } else {
    const body: CreateCommentRequest = {
      entity_type: entityType,
      entity_id: entityId,
      comment_text: commentText,
    };

    const createCommentCall: RobotoAPICall = {
      endpoint: commentsEndpoint,
      method: "POST",
      requestBody: JSON.stringify(body),
      orgId: orgId,
    };

    const { data, error } = await initiateRequest(createCommentCall);

    if (error) {
      onError(error);
      return;
    }

    if (data?.data) {
      onCommentSaved(data.data);
    }
  }
};
