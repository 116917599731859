import { PathParser } from "../PathParser";
import { type TopicRecord } from "../TopicRecord";

import { TopicNode, MessagePathNode } from "./node";

/**
 * Given a list of TopicRecords, construct a list of TopicNode trees.
 * MessagePaths have a `message_path` field that is a string of dot-separated keys,
 * which indicate their position in the tree. For example:
 * Given:
 * [
 *  {
 *    topic_name: "topic_name",
 *    message_paths: [
 *     { message_path: "foo", ... },
 *     { message_path: "foo.bar", ... },
 *     { message_path: "foo.bar.baz", ... }
 *    ],
 *    ...
 *  }
 * ]
 *
 * Expect:
 * {
 *   label: "topic_name",
 *   data: TopicRecord,
 *   depth: 0,
 *   children: [
 *     {
 *       label: "foo",
 *       data: MessagePathRecord,
 *       depth: 1,
 *       parent: <reference to the "topic_name" node>,
 *       children: [
 *           {
 *             label: "bar",
 *             data: MessagePathRecord,
 *             depth: 2,
 *             parent: <reference to the "foo" node>,
 *             children: [
 *               {
 *                 label: "baz",
 *                 data: MessagePathRecord,
 *                 depth: 3,
 *                 children: [],
 *                 parent: <reference to the "bar" node>
 *               }
 *           ]
 *         }
 *       ]
 *     }
 *   ]
 * }
 */
export function constructTree(topicRecords: TopicRecord[]): TopicNode[] {
  const tree: TopicNode[] = [];
  for (const topicRecord of topicRecords) {
    const topicNode = new TopicNode({
      label: topicRecord.topic_name,
      data: topicRecord,
    });
    tree.push(topicNode);

    // Heuristic: "parent_path" must come before "parent_path.child_path" for the tree construction to work.
    // This could otherwise be solved by explicitly tracking `parent_message_path` in the database.
    const messagePathRecords = topicRecord.message_paths.slice().sort((a, b) =>
      a.message_path.localeCompare(b.message_path, undefined, {
        numeric: true,
      }),
    );

    for (const messagePath of messagePathRecords) {
      const path = PathParser.splitMessagePath(messagePath.message_path);
      const parents = path.slice(0, path.length - 1);
      const current = path[path.length - 1];

      let parent: TopicNode | MessagePathNode = topicNode;
      for (const parentPath of parents) {
        const existing: MessagePathNode | undefined =
          parent.findChildByLabel(parentPath);
        if (existing !== undefined) {
          parent = existing;
        }
      }
      parent.addChild(messagePath, current);
    }
  }
  return tree.sort((a, b) => {
    return a.label.localeCompare(b.label, undefined, { numeric: true });
  });
}

/**
 * Find a MessagePathNode by traversing the constructed tree.
 * @param rootNode - The root node of the tree to start from (e.g., a TopicNode)
 * @param messagePath - The dot-separated message path string to search for
 * @returns The corresponding MessagePathNode if found, or null if not
 */
export function findMessagePathNode(
  rootNode: TopicNode,
  messagePath: string,
): MessagePathNode | null {
  const pathParts = PathParser.splitMessagePath(messagePath);

  function traverse(
    currentNode: MessagePathNode | TopicNode,
    index: number,
  ): MessagePathNode | null {
    // Base case: if we've traversed all parts
    if (index === pathParts.length) {
      return currentNode instanceof TopicNode ? null : currentNode;
    }

    // Find the child node matching the current part
    const childNode = currentNode.children.find(
      (child) => child.label === pathParts[index],
    );

    // If a matching child is found, continue traversing the tree
    return childNode ? traverse(childNode, index + 1) : null;
  }

  // Start the traversal from the root node
  return traverse(rootNode, 0);
}
