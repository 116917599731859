import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

import { DatasetRecord, UpdateDatasetRequest } from "../DatasetRecord";

interface UpdateDataset {
  datasetId: string;
  resourceOwnerId: string;
  updates: UpdateDatasetRequest;
}

export function useUpdateDataset() {
  const { datasetService } = React.useContext(DomainServicesContext);

  const queryClient = useQueryClient();

  return useMutation<DatasetRecord, Error, UpdateDataset>({
    mutationFn: async ({ datasetId, resourceOwnerId, updates }) => {
      return await datasetService.updateDataset(datasetId, updates, {
        resourceOwnerId,
      });
    },

    onSuccess: (datasetRecord, updateRequest) => {
      if (
        updateRequest.updates.metadata_changeset?.put_tags !== undefined ||
        updateRequest.updates.metadata_changeset?.remove_tags !== undefined
      ) {
        const requestBody = updateRequest.updates.metadata_changeset;

        queryClient.setQueriesData(
          {
            queryKey: ["queryResults"],
            exact: false,
          },

          // Note: this must align to structure in useQueryResults.ts
          (oldData: { pages?: { items: DatasetRecord[] }[] } | undefined) => {
            if (!oldData || !oldData.pages) {
              return oldData;
            }

            const newData = {
              ...oldData,
              pages: oldData.pages.map((page) => ({
                ...page,
                items: page.items.map((item) => {
                  if (item.dataset_id === datasetRecord.dataset_id) {
                    let newTags: string[] = item.tags ?? [];

                    if (
                      requestBody.put_tags &&
                      requestBody.put_tags.length > 0
                    ) {
                      newTags = newTags.concat(requestBody.put_tags);
                    }

                    if (requestBody.remove_tags) {
                      newTags = newTags.filter(
                        (oldTag) => !requestBody.remove_tags?.includes(oldTag),
                      );
                    }

                    return {
                      ...item,
                      tags: newTags,
                    };
                  }
                  return item;
                }),
              })),
            };

            return newData;
          },
        );
      }

      queryClient.setQueryData(
        ["datasetRecord", datasetRecord.dataset_id],
        datasetRecord,
      );
    },
  });
}
