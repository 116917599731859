import { CanonicalDataType, MessagePathPartType } from "@/shared/domain/topics";
import { Message } from "@/shared/mcap";
import { MapPath, TopicData } from "@/shared/state/visualization/schema/v1";

export type LatitudeDataType =
  | CanonicalDataType.LatDegFloat
  | CanonicalDataType.LatDegInt;

export type LongitudeDataType =
  | CanonicalDataType.LonDegFloat
  | CanonicalDataType.LonDegInt;

export function isLatitudeType(
  dataType: CanonicalDataType,
): dataType is LatitudeDataType {
  return (
    dataType === CanonicalDataType.LatDegFloat ||
    dataType === CanonicalDataType.LatDegInt
  );
}

export function isLongitudeType(
  dataType: CanonicalDataType,
): dataType is LongitudeDataType {
  return (
    dataType === CanonicalDataType.LonDegFloat ||
    dataType === CanonicalDataType.LonDegInt
  );
}

export function getCorrespondingGeoType(
  initialType: CanonicalDataType,
): CanonicalDataType | undefined {
  switch (initialType) {
    case CanonicalDataType.LatDegFloat:
      return CanonicalDataType.LonDegFloat;
    case CanonicalDataType.LonDegFloat:
      return CanonicalDataType.LatDegFloat;
    case CanonicalDataType.LatDegInt:
      return CanonicalDataType.LonDegInt;
    case CanonicalDataType.LonDegInt:
      return CanonicalDataType.LatDegInt;
    default:
      throw new Error("Unsupported canonical data type");
  }
}

export interface TimestampedLocation {
  latitude: number;
  longitude: number;
  timestamp: number;
}

export interface GeoMessagePath {
  messagePath: string;
  canonicalDataType: LatitudeDataType | LongitudeDataType;
}

export interface PathData {
  latMessagePath: GeoMessagePath;
  lonMessagePath: GeoMessagePath;
  messages: Message<Record<string, unknown>>[];
}

export function getNormalizedLatLon(
  message: Message<Record<string, unknown>>,
  latMessagePath: GeoMessagePath,
  lonMessagePath: GeoMessagePath,
): [number, number] | undefined {
  const latField = latMessagePath.messagePath;
  const lonField = lonMessagePath.messagePath;

  if (!(latField in message.data && lonField in message.data)) {
    return undefined;
  }

  const lat = normalizeCoordinate(
    message.data[latField],
    latMessagePath.canonicalDataType,
  );
  const lon = normalizeCoordinate(
    message.data[lonField],
    lonMessagePath.canonicalDataType,
  );

  if (lat !== undefined && lon !== undefined) {
    return [lat, lon];
  }

  throw new Error("Couldn't parse latitude and longitude in data");
}

function normalizeCoordinate(
  value: unknown,
  dataType: CanonicalDataType,
): number | undefined {
  if (typeof value !== "number") {
    return undefined;
  }

  switch (dataType) {
    case CanonicalDataType.LatDegFloat:
    case CanonicalDataType.LonDegFloat:
      return value;
    case CanonicalDataType.LatDegInt:
    case CanonicalDataType.LonDegInt:
      return value / 1e7;
    default:
      throw new Error("Unsupported coordinate data type");
  }
}

function getTopicDataType(topicData: TopicData): CanonicalDataType | undefined {
  const finalMsgPathPart = [...topicData.messagePath.parts].pop();
  if (finalMsgPathPart && finalMsgPathPart.type === MessagePathPartType.Attr) {
    return finalMsgPathPart.dataType;
  }
}

function getTopicDataField(topicData: TopicData): string | undefined {
  return topicData.messagePath.dotPath.split(".").pop();
}

/**
 * Extract latitude and longitude message paths from the path data.
 */
export function getGeoMessagePaths(path: MapPath): {
  latMessagePath: GeoMessagePath;
  lonMessagePath: GeoMessagePath;
} {
  const [dataA, dataB] = path.data;
  const msgPathA = getTopicDataField(dataA);
  const dataTypeA = getTopicDataType(dataA);
  const msgPathB = getTopicDataField(dataB);
  const dataTypeB = getTopicDataType(dataB);

  if (!msgPathA || !dataTypeA || !msgPathB || !dataTypeB) {
    throw new Error("Missing required geographic data");
  }

  const latMessagePath: GeoMessagePath = (() => {
    if (isLatitudeType(dataTypeA)) {
      return { messagePath: msgPathA, canonicalDataType: dataTypeA };
    } else if (isLatitudeType(dataTypeB)) {
      return { messagePath: msgPathB, canonicalDataType: dataTypeB };
    } else {
      throw new Error("Missing a latitude type");
    }
  })();

  const lonMessagePath: GeoMessagePath = (() => {
    if (isLongitudeType(dataTypeA)) {
      return { messagePath: msgPathA, canonicalDataType: dataTypeA };
    } else if (isLongitudeType(dataTypeB)) {
      return { messagePath: msgPathB, canonicalDataType: dataTypeB };
    } else {
      throw new Error("Missing a longitude type");
    }
  })();

  return { latMessagePath, lonMessagePath };
}
