export const imageFileTypes = ["jpg", "jpeg", "png", "gif"];
export const videoFileTypes = ["mp4", "ogg", "webm"];

export const roboticsFileTypes = ["bag", "csv", "db3", "log", "mcap", "ulg"];
export const customRoboticsFileTypes = ["daq", "daq.gz", "protoLog"];

export const plaintextFileTypes = [
  "asc",
  "bmd",
  "cfg",
  "conf",
  "csv",
  "env",
  "ini",
  "json",
  "log",
  "md",
  "rtf",
  "sql",
  "tsv",
  "txt",
  "xml",
  "yaml",
  "yml",
];

export const isImage = (filename: string) => {
  const imageFileExtensionTypes = new RegExp(
    `\\.(${imageFileTypes.join("|")})$`,
    "i",
  );
  return imageFileExtensionTypes.test(filename.toLowerCase());
};

export const isVideo = (filename: string) => {
  const videoFileExtensionTypes = new RegExp(
    `\\.(${videoFileTypes.join("|")})$`,
    "i",
  );
  return videoFileExtensionTypes.test(filename.toLowerCase());
};

export const isText = (filename: string) => {
  const plaintextFileExtensionTypes = new RegExp(
    `\\.(${plaintextFileTypes.join("|")})$`,
    "i",
  );
  return plaintextFileExtensionTypes.test(filename.toLowerCase());
};

export const isMarkdown = (filename: string) => {
  return filename.toLowerCase().match(/\.(md)(\?.*)?$/i);
};

export const isWebRenderable = (filename: string) => {
  return filename.toLowerCase().match(/\.(html|pdf)(\?.*)?$/i);
};

export const isVisibleMedia = (filename: string) => {
  return (
    isImage(filename) ||
    isVideo(filename) ||
    isText(filename) ||
    isMarkdown(filename) ||
    isWebRenderable(filename)
  );
};

export function canHaveTopics(filename: string): boolean {
  const allRoboticsFileTypes = [
    ...roboticsFileTypes,
    ...customRoboticsFileTypes,
  ];
  const roboticFileExtensionTypes = new RegExp(
    `\\.(${allRoboticsFileTypes.join("|")})$`,
    "i",
  );
  return roboticFileExtensionTypes.test(filename.toLowerCase());
}
