import {
  useInfiniteQuery,
  type UseInfiniteQueryResult,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { PaginatedResponse } from "@/shared/http";

export type QueryResults<T> = {
  resultsQuery: UseInfiniteQueryResult<T[], Error>;
  countQuery: UseQueryResult<number>;
};

/**
 * Custom hook to retrieve query results with pagination.
 *
 * @template T - The type of the query results.
 * @param {string | undefined} queryId - The unique identifier for the query. If undefined, no query will be performed.
 * @param {number} [pageSize=250] - The number of results per page retrieved from the backend.
 *                                Default is set to 250, which is  higher than typical frontend pagination
 *                                defaults (e.g., 10, 25, or 50). This provides an upfront buffer capable
 *                                of handling multiple frontend pages worth of results, improving performance
 *                                and reducing the need for frequent backend requests.
 */
export function useQueryResults<T>(
  queryId: string | undefined,
  pageSize: number = 250,
): QueryResults<T> {
  const { queryService } = React.useContext(DomainServicesContext);

  const resultsQuery = useInfiniteQuery({
    queryKey: ["queryResults", queryId],
    queryFn: ({ pageParam, signal }) => {
      if (queryId === undefined) {
        return Promise.reject(new Error("Invalid queryId"));
      }

      return queryService.getQueryResults(queryId, {
        abortSignal: signal,
        nextPageToken: pageParam,
        pageSize: pageSize,
      });
    },
    select: (data) => {
      // Flatten backend pages of items into a single list of items.
      return data.pages.flatMap((value) => value.items);
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage: PaginatedResponse<T>) => lastPage.next_token,
    enabled: queryId !== undefined,
    staleTime: Infinity,
  });

  const countQuery = useQuery({
    queryKey: ["queryResultsCount", queryId],
    queryFn: ({ signal }) => {
      if (queryId === undefined) {
        return Promise.reject(new Error("Invalid queryId"));
      }

      return queryService.getQueryResultCount(queryId, { abortSignal: signal });
    },
  });
  return { resultsQuery, countQuery };
}
