import { Skeleton } from "@mui/material";

import { RobotoTypography } from "@/shared/components/RobotoTypography";
import { AssociationType } from "@/shared/domain/association";

import { useWorkspaceEvent } from "../WorkspaceCtx";

import styles from "./EventScope.module.css";
import {
  AssociatedDataset,
  AssociatedFile,
  AssociatedMessagePath,
  AssociatedTopic,
} from "./scopes";

interface EventScopeProps {
  eventId: string;
}

export function EventScope({ eventId }: EventScopeProps) {
  const eventQuery = useWorkspaceEvent(eventId);

  if (eventQuery.isLoading) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.sectionTitle}>
          Scope
        </RobotoTypography>
        <Skeleton height="24px" variant="rounded" />
      </section>
    );
  }

  if (eventQuery.isError) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.sectionTitle}>
          Scope
        </RobotoTypography>
        <RobotoTypography>Failed to load event scope</RobotoTypography>
      </section>
    );
  }

  return (
    <section>
      <RobotoTypography component={"h3"} className={styles.sectionTitle}>
        Scope
      </RobotoTypography>
      <div className={styles.associationList}>
        {eventQuery.data.associations.map((association) => {
          switch (association.association_type) {
            case AssociationType.Dataset:
              return (
                <AssociatedDataset
                  key={association.association_id}
                  datasetId={association.association_id}
                />
              );
            case AssociationType.File:
              return (
                <AssociatedFile
                  key={association.association_id}
                  fileId={association.association_id}
                />
              );
            case AssociationType.MessagePath:
              return (
                <AssociatedMessagePath
                  key={association.association_id}
                  messagePathId={association.association_id}
                />
              );
            case AssociationType.Topic:
              return (
                <AssociatedTopic
                  key={association.association_id}
                  topicId={association.association_id}
                />
              );
            default:
              return <span key={association.association_id}>Unknown</span>;
          }
        })}
      </div>
    </section>
  );
}
