import mime from "mime";

import { Condition, SearchQueryBody } from "@/types/search";

import { HttpClient, robotoHeaders, PaginatedResponse } from "../../http";

import { type FileRecord, DirectoryRecord } from "./FileRecord";

interface Options {
  abortSignal: AbortSignal;
  resourceOwnerId: string;
  searchParams: URLSearchParams;
}

interface PutOptions extends Options {
  body?: BodyInit | null;
}

export interface DirectoryContentsPage {
  files: FileRecord[];
  directories: DirectoryRecord[];
  next_token: string | null;
}

interface GetFilesForDirectoryParams {
  path: string;
  fileNameSearchTerm: string;
  datasetId: string;
  pageSize: number;
  nextToken?: string;
  showHiddenFiles?: boolean;
  extensions?: string[];
  options?: Partial<Options>;
}

interface GetItemsForDirectoryParams {
  directoryPath: string;
  datasetId: string;
  pageSize: number;
  nextToken?: string;
  showHiddenFiles?: boolean;
  extensions?: string[];
  options?: Partial<Options>;
}

export interface IFileService {
  abortTransactions(
    transactionIds: string[],
    options?: Partial<Options>,
  ): Promise<void>;
  getFileRecord(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<FileRecord>;
  putFileRecord(
    fileId: string,
    options?: Partial<PutOptions>,
  ): Promise<FileRecord>;
  renameFile(
    fileId: string,
    datasetId: string,
    currentPath: string,
    newName: string,
    options?: Partial<Options>,
  ): Promise<FileRecord>;
  renameDirectory(
    datasetId: string,
    currentPath: string,
    newName: string,
    options?: Partial<Options>,
  ): Promise<DirectoryRecord>;
  getPresignedDownloadUrl(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<URL>;
  getJsonFileContents<T>(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<T>;
  getTagsForOrg(options?: Partial<Options>): Promise<string[]>;
  getMetadataKeysForOrg(options?: Partial<Options>): Promise<string[]>;
  getItemsForDirectory(
    params: GetItemsForDirectoryParams,
  ): Promise<DirectoryContentsPage>;
  getFilesForDirectory(
    params: GetFilesForDirectoryParams,
  ): Promise<PaginatedResponse<FileRecord>>;
  getExtensionsForDirectory(
    datasetId: string,
    directoryPath: string,
    options?: Partial<Options>,
  ): Promise<string[]>;
  deleteDirectories(
    datasetId: string,
    directoryPaths: string[],
    options?: Partial<Options>,
  ): Promise<void>;
  deleteFile(fileId: string, options?: Partial<Options>): Promise<void>;
}

export class FileService implements IFileService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async abortTransactions(
    transactionIds: string[],
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/transactions/abort`,
    );
    const body = {
      transaction_ids: transactionIds,
    };
    await this.#httpClient.post(requestUrl, {
      body: JSON.stringify(body),
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return;
  }

  public async getFileRecord(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<FileRecord> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/record/${fileId}`,
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return await response.json<FileRecord>();
  }

  public async putFileRecord(
    fileId: string,
    options?: Partial<PutOptions>,
  ): Promise<FileRecord> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/record/${fileId}`,
    );
    const response = await this.#httpClient.put(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
      body: options?.body,
    });
    return await response.json<FileRecord>();
  }

  public async renameFile(
    fileId: string,
    datasetId: string,
    currentPath: string,
    newName: string,
    options?: Partial<Options>,
  ): Promise<FileRecord> {
    //strip leading and trailing slashes
    const cleanOldPath = currentPath.replace(/^\/+|\/+$/g, "");

    const newPath = `${cleanOldPath.split("/").slice(0, -1).join("/")}/${newName}`;

    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/${fileId}/rename`,
    );

    const body = {
      association_id: datasetId,
      new_path: newPath,
    };

    const response = await this.#httpClient.put(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
      body: JSON.stringify(body),
    });

    return await response.json<FileRecord>();
  }

  public async renameDirectory(
    datasetId: string,
    currentPath: string,
    newName: string,
    options?: Partial<Options>,
  ): Promise<DirectoryRecord> {
    //strip leading and trailing slashes
    const cleanOldPath = currentPath.replace(/^\/+|\/+$/g, "");

    const slice = cleanOldPath.split("/").slice(0, -1).join("/");
    const cleanNewPath = `${slice}/${newName}`.replace(/^\/+|\/+$/g, "");

    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/directory/rename`,
    );

    const body = {
      new_path: cleanNewPath,
      old_path: cleanOldPath,
    };

    const response = await this.#httpClient.put(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
      body: JSON.stringify(body),
    });

    return await response.json<DirectoryRecord>();
  }

  public async getPresignedDownloadUrl(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<URL> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/${fileId}/signed-url`,
      options?.searchParams,
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    const { url: signedUrl } = await response.json<{ url: string }>();
    return new URL(signedUrl);
  }

  public async getJsonFileContents<T>(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<T> {
    const requestUrl = await this.getPresignedDownloadUrl(fileId, options);

    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      excludeAuth: true,
    });

    return (await response.raw.json()) as T;
  }

  public async getTagsForOrg(options?: Partial<Options>): Promise<string[]> {
    if (!options?.resourceOwnerId) {
      throw Error("getTagsForOrg requires an org ID, none was provided");
    }

    const requestUrl = this.#httpClient.constructUrl("v1/files/tags");
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    return await response.json<string[]>();
  }

  public async getMetadataKeysForOrg(
    options?: Partial<Options>,
  ): Promise<string[]> {
    if (!options?.resourceOwnerId) {
      throw Error(
        "getMetadataKeysForOrg requires an org ID, none was provided",
      );
    }

    const requestUrl = this.#httpClient.constructUrl("v1/files/metadata/keys");
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    return await response.json<string[]>();
  }

  public async getItemsForDirectory(
    params: GetItemsForDirectoryParams,
  ): Promise<DirectoryContentsPage> {
    const {
      directoryPath,
      datasetId,
      pageSize,
      nextToken,
      showHiddenFiles,
      options,
      extensions,
    } = params;

    const urlParams = new URLSearchParams({
      directory_path: directoryPath,
      dataset_id: datasetId,
      page_size: pageSize.toString(),
    });

    if (nextToken) {
      urlParams.append("after", nextToken);
    }

    if (showHiddenFiles) {
      urlParams.append("show_hidden_files", "true");
    }

    if (extensions?.length) {
      urlParams.append("extensions", extensions.join(","));
    }

    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/files/directory-contents`,
      urlParams,
    );

    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return await response.json<DirectoryContentsPage>();
  }

  public async getFilesForDirectory(
    params: GetFilesForDirectoryParams,
  ): Promise<PaginatedResponse<FileRecord>> {
    const {
      path,
      fileNameSearchTerm,
      datasetId,
      pageSize,
      nextToken,
      showHiddenFiles,
      options,
      extensions,
    } = params;

    const directoryPath = path.replace(/^\/+|\/+$/g, "");

    const requestUrl = this.#httpClient.constructUrl(`v1/files/query`);

    const datasetCondition: Condition = {
      field: "association_id",
      comparator: "EQUALS",
      value: datasetId,
    };

    const statusCondition: Condition = {
      field: "status",
      comparator: "EQUALS",
      value: "available",
    };

    const conditions: Condition[] = [datasetCondition, statusCondition];

    if (extensions?.length) {
      const extensionConditions: Condition[] = extensions.map((extension) => ({
        field: "relative_path",
        comparator: "LIKE",
        value: `%${extension}`,
      }));

      conditions.push({
        operator: "OR",
        conditions: extensionConditions,
      });
    }

    if (directoryPath === "") {
      conditions.push({
        field: "relative_path",
        comparator: "LIKE",
        value: `%${fileNameSearchTerm}%`,
      });
      conditions.push({
        field: "relative_path",
        comparator: "NOT_LIKE",
        value: `%/%`,
      });
      if (!showHiddenFiles) {
        conditions.push({
          field: "relative_path",
          comparator: "NOT_LIKE",
          value: `.%`,
        });
      }
    } else {
      conditions.push({
        field: "relative_path",
        comparator: "LIKE",
        value: `${directoryPath}/%${fileNameSearchTerm}%`,
      });
      conditions.push({
        field: "relative_path",
        comparator: "NOT_LIKE",
        value: `${directoryPath}/%/%`,
      });
      if (!showHiddenFiles) {
        conditions.push({
          field: "relative_path",
          comparator: "NOT_LIKE",
          value: `${directoryPath}/.%`,
        });
      }
    }

    const body: SearchQueryBody = {
      limit: pageSize,
      condition: {
        operator: "AND",
        conditions: conditions,
      },
      sort_by: "relative_path",
      sort_direction: "ASC",
    };

    if (nextToken) {
      body["after"] = nextToken;
    }

    const response = await this.#httpClient.post(requestUrl, {
      body: JSON.stringify(body),
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return await response.json<PaginatedResponse<FileRecord>>();
  }

  public async getExtensionsForDirectory(
    datasetId: string,
    directoryPath: string,
    options?: Partial<Options>,
  ): Promise<string[]> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/files/directory-extensions`,
      new URLSearchParams({ directory_path: directoryPath }),
    );

    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return await response.json<string[]>();
  }

  /**
   * Prepares fetching an AWS signed url with the correct query params.
   *
   * @param file - The file record
   * @param forDownload - Whether the signed URL is being generated to download a file
   * @returns
   */
  public getSignedUrlParams(file: FileRecord, forDownload?: boolean) {
    const queryParams = new URLSearchParams({ redirect: "false" });

    if (forDownload) {
      // If the signed URL is being generated to download a file
      // ensure the ContentDisposition is set to `attachment` to prompt
      // a browser download instead of serving the content inline
      queryParams.set("override_content_disposition", "attachment");
    } else {
      // AWS sets ContentType to `application/octet-stream` by default
      // on uploaded items, which prevents us from serving content.
      // Fortunately, the ContentType can be overridden in requests
      // so we can look up the correct MIME type and set it accordingly.
      // This ensures files like .html / .pdf can be served in browser.
      const fallbackType = "application/octet-stream";
      const mimeType = mime.getType(file.relative_path) || fallbackType;
      queryParams.set("override_content_type", mimeType);
    }

    return queryParams;
  }

  public async deleteDirectories(
    datasetId: string,
    directoryPaths: string[],
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/files/delete-directories`,
    );

    const body = {
      directory_paths: directoryPaths,
    };

    await this.#httpClient.post(requestUrl, {
      body: JSON.stringify(body),
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return;
  }

  public async deleteFile(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(`v1/files/${fileId}`);

    await this.#httpClient.delete(requestUrl, {
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return;
  }
}
