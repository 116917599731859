import * as React from "react";

import { ActionService, TriggerService } from "@/shared/domain/actions";
import { DatasetService } from "@/shared/domain/datasets";
import { DeviceService } from "@/shared/domain/devices";
import { EventService } from "@/shared/domain/events";
import { ExperienceService } from "@/shared/domain/experiences";
import { FileService } from "@/shared/domain/files";
import { HealthService } from "@/shared/domain/health";
import { LimitService } from "@/shared/domain/limits/LimitService";
import { NotificationService } from "@/shared/domain/notifications";
import { OrgService } from "@/shared/domain/orgs";
import { QueryService } from "@/shared/domain/query";
import { TokenService } from "@/shared/domain/tokens";
import { TopicService } from "@/shared/domain/topics";
import { UserService } from "@/shared/domain/users/UserService";
import { WorkspaceService } from "@/shared/domain/workspaces/WorkspaceService";

interface Context {
  actionService: ActionService;
  datasetService: DatasetService;
  devices: DeviceService;
  events: EventService;
  experienceService: ExperienceService;
  files: FileService;
  healthService: HealthService;
  limitSummary: LimitService;
  notifications: NotificationService;
  orgService: OrgService;
  queryService: QueryService;
  topics: TopicService;
  tokens: TokenService;
  triggerService: TriggerService;
  users: UserService;
  workspaceService: WorkspaceService;
}

export const defaultDomainContextFactory = (overrides?: Partial<Context>) => ({
  get actionService(): ActionService {
    throw new Error("ActionService is missing in DomainServicesContext");
  },
  get datasetService(): DatasetService {
    throw new Error("DatasetService missing in DomainServicesContext");
  },
  get devices(): DeviceService {
    throw new Error("DeviceService missing in DomainServicesContext");
  },
  get events(): EventService {
    throw new Error("EventService missing in DomainServicesContext");
  },
  get experienceService(): ExperienceService {
    throw new Error("ExperienceService missing in DomainServicesContext");
  },
  get files(): FileService {
    throw new Error("FileService missing in DomainServicesContext");
  },
  get healthService(): HealthService {
    throw new Error("HealthService missing in DomainServicesContext");
  },
  get limitSummary(): LimitService {
    throw new Error("LimitService missing in DomainServicesContext");
  },
  get queryService(): QueryService {
    throw new Error("QueryService missing in DomainServicesContext");
  },
  get notifications(): NotificationService {
    throw new Error("NotificationService missing in DomainServicesContext");
  },
  get orgService(): OrgService {
    throw new Error("OrgService missing in DomainServicesContext");
  },
  get topics(): TopicService {
    throw new Error("TopicService missing in DomainServicesContext");
  },
  get tokens(): TokenService {
    throw new Error("TokenService missing in DomainServicesContext");
  },
  get triggerService(): TriggerService {
    throw new Error("TriggerService is missing in DomainServicesContext");
  },
  get users(): UserService {
    throw new Error("UserService is missing in DomainServicesContext");
  },
  get workspaceService(): WorkspaceService {
    throw new Error("WorkspaceService is missing in DomainServicesContext");
  },
  ...overrides,
});

export const DomainServicesContext = React.createContext<Context>(
  defaultDomainContextFactory(),
);

export const useDomainServices = (): Context => {
  return React.useContext(DomainServicesContext);
};
