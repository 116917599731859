export const NS_PER_MS = 1e6;
export const NS_PER_MS_BIGINT = BigInt(NS_PER_MS);
export const MS_PER_S = 1000;
export const NS_PER_S = NS_PER_MS * MS_PER_S;
export const NS_PER_S_BIGINT = BigInt(NS_PER_S);

export function milliSecToNanoSec(value: number): bigint {
  const ns = value * NS_PER_MS;
  return BigInt(ns.toFixed(0));
}

export function nanoSecToMilliSec(value: bigint): number {
  const sec = Number(value / NS_PER_S_BIGINT);
  const nsec = Number(value % NS_PER_S_BIGINT);
  return sec * MS_PER_S + nsec / NS_PER_MS;
}

export function nanoSecToSec(value: bigint): number {
  const sec = Number(value / NS_PER_S_BIGINT);
  const nsec = Number(value % NS_PER_S_BIGINT);
  return sec + nsec / NS_PER_S;
}

export function nanoSecToDate(ns: bigint) {
  const ms = nanoSecToMilliSec(ns);
  return new Date(ms);
}

export const nanoSecToLocalTimestamp = (ns: bigint) => {
  const date = nanoSecToDate(ns);

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Format the date as YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  // Format the time
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
    hour12: true,
    timeZoneName: "short",
  };

  const formattedTime = date.toLocaleTimeString(undefined, timeOptions);

  return `${formattedDate} ${formattedTime}`;
};

export const nanoSecToRosTimeStamp = (ns: bigint) => {
  const sec = ns / NS_PER_S_BIGINT;
  const nanoSec = ns % NS_PER_S_BIGINT;
  const ts = `${sec}.${nanoSec}`;
  return ts;
};

/**
 * Converts a string representing a number of seconds to a BigInt in nanoseconds.
 * Handles fractional seconds and ensures that nanoseconds are properly rounded and normalized.
 *
 * @param value - The string representing seconds, possibly with fractional seconds.
 * @returns The equivalent BigInt in nanoseconds.
 */
export const strSecToBigIntNanosec = (value: string): bigint => {
  const [secondsPart, nanosecondsPart = "0"] = value.split(".");

  let sec = BigInt(parseInt(secondsPart, 10));

  // Handle fractional seconds and rounding
  const digitsShort = 9 - nanosecondsPart.length;
  let nsec = BigInt(
    Math.round(parseInt(nanosecondsPart, 10) * 10 ** digitsShort),
  );

  // Normalize if the nsec is >= 1e9
  if (nsec >= 1_000_000_000n) {
    sec += nsec / 1_000_000_000n; // Add full seconds from nsec overflow
    nsec = nsec % 1_000_000_000n; // Keep only the remainder as nanoseconds
  }

  return sec * 1_000_000_000n + nsec;
};
