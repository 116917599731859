import { Skeleton } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { useParams } from "react-router-dom";

import { EphemeralWorkspaceStateContext } from "@/shared/components/visualization/WorkspaceCtx/EphemeralWorkspaceState";
import { VizConfig } from "@/shared/config";
import { useSharedWorkspace } from "@/shared/domain/workspaces";
import { useResizable } from "@/shared/hooks";
import {
  actions,
  useFiles,
  useVizDispatch,
} from "@/shared/state/visualization";

import { DndContainer } from "../DndContainer";
import { Header } from "../Header";
import { PanelBoard } from "../PanelBoard";
import { Sidebar } from "../Sidebar";
import { Timeline } from "../Timeline";
import { Timer } from "../timer";
import { useWorkspaceContextForFiles } from "../WorkspaceCtx";

import { type Notification, Notifications } from "./Notifications";
import styles from "./Workspace.module.css";

interface WorkspaceProps {
  showHeader?: boolean;
}

export function Workspace({ showHeader = true }: WorkspaceProps) {
  const dispatch = useVizDispatch();
  const files = useFiles();
  const fileIds = React.useMemo(
    () => files.map((file) => file.fileId),
    [files],
  );
  const { workspaceId } = useParams();
  const sharedWorkspaceQuery = useSharedWorkspace(workspaceId);

  const [timeSpans, setTimeSpans] = React.useState<[bigint, bigint][]>([]);
  const workspaceContext = useWorkspaceContextForFiles(fileIds, timeSpans);

  React.useEffect(() => {
    const fileToTimeBounds = workspaceContext.topics.reduce(
      (accum, topic) => {
        if (topic.start_time === null || topic.end_time === null) {
          return accum;
        }

        if (!(topic.association.association_id in accum)) {
          accum[topic.association.association_id] = [
            topic.start_time,
            topic.end_time,
          ];
        }

        const currAccum = accum[topic.association.association_id];

        if (currAccum[0] > topic.start_time) {
          currAccum[0] = topic.start_time;
        }

        if (currAccum[1] < topic.end_time) {
          currAccum[1] = topic.end_time;
        }

        return accum;
      },
      {} as { [key: string]: [bigint, bigint] },
    );
    const spans: [bigint, bigint][] = Object.values(fileToTimeBounds);

    const mergedAndSorted = Timer.sortAndMergeSpans(spans);

    setTimeSpans(mergedAndSorted);
    workspaceContext.timer.updateTimeSpans(mergedAndSorted);
  }, [workspaceContext.topics, workspaceContext.timer]);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const resizeHandleRef = React.useRef<HTMLElement>(null);
  const { isDragging, separatorProps, layout1Size, layout2Size } = useResizable(
    {
      axis: "x",
      containerRef,
      resizeHandleRef,

      // Sidebar will initially take 20% of available space
      initialLayout1Size: 0.2,

      // Panelboard will initially take 80% of available space
      initialLayout2Size: 0.8,

      // Neither the sidebar nor panel board's width will
      // shrink lower than 5% or expand greater than 95%.
      minSize: 0.05,
      maxSize: 0.95,
    },
  );

  const [workspaceNotifications, setWorkspaceNotifications] = React.useState<
    Notification[]
  >([]);

  // Tell the root panels when we're changing the workspace layout
  React.useEffect(() => {
    dispatch(actions.setAllLayoutsResizing(isDragging));
  }, [isDragging, dispatch]);

  React.useEffect(() => {
    if (sharedWorkspaceQuery.isSuccess && sharedWorkspaceQuery.data) {
      const config = sharedWorkspaceQuery.data.config;
      const isValid = VizConfig.isValid(config);
      if (isValid) {
        const newVizConfig = VizConfig.from_obj(config);
        dispatch(actions.replaceState(newVizConfig.toObject()));
      } else {
        // The workspace record has an invalid config.
        // Likely the result of a non-backward compatible viz schema change.
        const notification: Notification = {
          id: "viz-config-invalid",
          message: [
            `The workspace provided via the URL ('${sharedWorkspaceQuery.data.workspace_id}') cannot be reconstituted.`,
            "This is likely due to an update to how we internally model these workspaces.",
            "We're working to support better backwards compatibility.",
          ].join(" "),
          severity: "error",
        };
        setWorkspaceNotifications((prevNotifications) => {
          if (prevNotifications.find((n) => n.id === notification.id)) {
            return prevNotifications;
          }
          return [...prevNotifications, notification];
        });
      }
    }
  }, [dispatch, sharedWorkspaceQuery.data, sharedWorkspaceQuery.isSuccess]);

  return (
    <EphemeralWorkspaceStateContext.Provider value={workspaceContext}>
      <div className={styles.workspaceContainer}>
        <Header showHeader={showHeader} />
        <Notifications
          onDismiss={(notification) => {
            setWorkspaceNotifications((prevNotifications) =>
              prevNotifications.filter((n) => n.id !== notification.id),
            );
          }}
          notifications={workspaceNotifications}
        />
        <DndContainer>
          <div className={styles.primaryContent} ref={containerRef}>
            <Sidebar
              style={{ flexBasis: `${layout1Size * 100}%` }}
              separatorProps={separatorProps}
              isDragging={isDragging}
              resizeHandleRef={resizeHandleRef}
            />

            {sharedWorkspaceQuery.isLoading ? (
              <Skeleton className={styles.loadingSkeleton} variant="rounded" />
            ) : (
              <PanelBoard
                className={classNames(styles.panelBoard, {
                  [styles.resizing]: isDragging,
                })}
                errorMsg={
                  sharedWorkspaceQuery.error?.message ||
                  workspaceContext.error?.message
                }
                style={{ flexBasis: `${layout2Size * 100}%` }}
              />
            )}
          </div>
        </DndContainer>
        <Timeline />
      </div>
    </EphemeralWorkspaceStateContext.Provider>
  );
}
