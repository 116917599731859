import * as React from "react";

import { ActionService, TriggerService } from "@/shared/domain/actions";
import { DatasetService } from "@/shared/domain/datasets";
import { DeviceService } from "@/shared/domain/devices";
import { EventService } from "@/shared/domain/events";
import { ExperienceService } from "@/shared/domain/experiences";
import { FileService } from "@/shared/domain/files";
import { HealthService } from "@/shared/domain/health";
import { LimitService } from "@/shared/domain/limits/LimitService";
import { NotificationService } from "@/shared/domain/notifications";
import { OrgService } from "@/shared/domain/orgs";
import { QueryService } from "@/shared/domain/query";
import { TokenService } from "@/shared/domain/tokens";
import { TopicService } from "@/shared/domain/topics";
import { UserService } from "@/shared/domain/users/UserService";
import { WorkspaceService } from "@/shared/domain/workspaces/WorkspaceService";

import { HttpClientContext } from "../HttpClient";

import { DomainServicesContext } from "./DomainServicesContext";

export function DomainServicesProvider({ children }: React.PropsWithChildren) {
  const httpClient = React.useContext(HttpClientContext).httpClient;

  const domainServicesContext = React.useMemo(() => {
    return {
      actionService: new ActionService(httpClient),
      datasetService: new DatasetService(httpClient),
      devices: new DeviceService(httpClient),
      events: new EventService(httpClient),
      experienceService: new ExperienceService(httpClient),
      files: new FileService(httpClient),
      healthService: new HealthService(httpClient),
      limitSummary: new LimitService(httpClient),
      notifications: new NotificationService(httpClient),
      orgService: new OrgService(httpClient),
      queryService: new QueryService(httpClient),
      tokens: new TokenService(httpClient),
      topics: new TopicService(httpClient),
      triggerService: new TriggerService(httpClient),
      users: new UserService(httpClient),
      workspaceService: new WorkspaceService(httpClient),
    };
  }, [httpClient]);

  return (
    <DomainServicesContext.Provider value={domainServicesContext}>
      {children}
    </DomainServicesContext.Provider>
  );
}
