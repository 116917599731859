import * as React from "react";

import { PlotTool } from "../plotTools/PlotTool";

import { PlotPanelContext } from "./PlotPanelContext";

export function PlotPanelProvider({ children }: React.PropsWithChildren) {
  const [activeTool, setActiveTool] = React.useState<PlotTool>(PlotTool.Move);
  const [isViewportSynced, setViewportSynced] = React.useState(true);
  const [isLegendShown, setIsLegendShown] = React.useState(true);

  return (
    <PlotPanelContext.Provider
      value={{
        activeTool,
        setActiveTool,
        isViewportSynced,
        setViewportSynced,
        isLegendShown,
        setIsLegendShown,
      }}
    >
      {children}
    </PlotPanelContext.Provider>
  );
}
